.btn {
  margin: 0 10px 10px 0;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  color: #333;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn.active {
  background-color: #fff;
  color: #000000;
  border: 2px solid #FFA500;
}

.circle {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid orange;
  text-align: center;
  line-height: 40px;
  font-weight: bold;
  color: orange;
}

@media (max-width: 768px) {
  .btn {
    padding: 10px 15px;
    font-size: 14px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1000px; /* Adjust the value to your desired maximum width */
  }
}


.play-button {
  width: 40px; /* Smaller button width */
  height: 40px; /* Smaller button height */
  background-color: black; /* Dark orange */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.play-icon {
  width: 0;
  height: 0;
  border-top: 10px solid transparent; /* Smaller triangle size */
  border-bottom: 10px solid transparent; /* Smaller triangle size */
  border-left: 14px solid white; /* Adjusted for right-pointing play button and smaller triangle */
  position: relative;
  left: 1px; /* Adjusted to center the triangle */
}


