
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background: linear-gradient(135deg,#EEEEEE ,#EEEEEE, #EEEEEE );
    background-size: cover;
    background-attachment: fixed;
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    color: #333; /* Change the text color to improve readability */
}